<template>
    <section class="ver-rutas pt-3">
        <div class="row mx-0">
            <div class="col-12">
                <div class="row mx-0 align-items-center">
                    <div class="rounded-circle d-middle-center mx-2 cr-pointer bg-general3 text-white" style="height:20px;width:20px;" @click="$router.push({name: 'admin.rutas.turno'})">
                        <i class="icon-left" />
                    </div>
                    <div class="col-auto px-0 text-general f-17 f-600">
                        Turno 58452
                    </div>
                    <i class="icon-fecha-fin text-general ml-auto f-20" />
                    <div class="col-auto pl-1 text-center text-general">
                        <p class="f-17 f-400">10 Ago. 2021</p>
                        <p class="f-15">Fecha de uso</p>
                    </div>
                    <i class="icon-clock text-general f-20" />
                    <div class="col-auto pl-1 text-general">
                        <p class="f-17 f-400">10:00 am - 6:00 p.m</p>
                        <p class="f-15">Horario</p>
                    </div>
                    <i class="icon-info-circled-alt text-general f-20" />
                    <div class="col-auto pl-1 text-general">
                        <p class="f-17 f-400">Descripción</p>
                        <p class="f-15 text-general-red cr-pointer f-600" style="text-decoration:underline;" @click="detalleSolicitud">Ver</p>
                    </div>
                    <router-link :to="{name: 'admin.rutas.anadir-pedidos', params: {id_ruta: 1}}">
                        <div class="d-middle bg-general px-3 shadow br-8 text-white cr-pointer ml-2" style="height:32px;">
                            <i class="icon-plus f-18" />
                            Añadir ruta
                        </div>
                    </router-link>
                    <div class="col-auto text-general2 ml-auto px-0">
                        <i class="icon-playlist-edit f-18" />
                        Borrador
                    </div>
                    <div class="d-middle bg-general px-3 shadow br-8 text-white cr-pointer ml-3" style="height:32px;" @click="enviarSolicitud">
                        Enviar Solicitud
                    </div>
                    <div class="btn-ver br-8 border mx-1 d-middle-center text-general cr-pointer" @click="editarTurno">
                        <i class="icon-pencil-outline" />
                    </div>
                    <div class="btn-ver br-8 border mx-1 d-middle-center text-general cr-pointer" @click="eliminar">
                        <i class="icon-delete-outline" />
                    </div>
                </div>
                <div class="row mx-0 align-items-center mt-3 px-2 py-2 bg-white br-12">
                    <div class="bg-light-f5 d-middle-center mr-2 rounded-circle" style="width:34px;height:34px;">
                        <i class="icon-account-search f-18" />
                    </div>
                    <div class="col-auto pl-2 text-general">
                        <p class="f-17">Sin asignar</p>
                        <p class="f-15">Driver</p>
                    </div>
                    <i class="icon-truck f-23 text-general ml-4 mr-2" />
                    <div class="col-auto pl-2 text-general">
                        <p class="f-17">Sin Asignar</p>
                        <p class="f-15">Vehículo</p>
                    </div>
                    <div class="col-3 d-flex ml-2">
                        <i class="icon-map-search-outline text-general f-22" />
                        <el-input v-model="distancia" placeholder="Distancia de rastreo (km)" class="w-100" />
                    </div>
                    <i class="icon-pedidos f-20 text-general" />
                    <div class="col-auto pl-2 text-general">
                        <p class="f-17">47</p>
                        <p class="f-15">Pedidos</p>
                    </div>
                </div>
                <div class="d-flex mx-0 mt-3">
                    <div class="bg-white border br-12 px-0 mx-2 br-t-10 overflow-auto custom-scroll" style="width:calc(100vw - 600px)">
                        <div class="mx-0" style="display:inline-flex;">
                            <div v-for="data in 10" :key="data" class="column-route px-0  border-right px-0">
                                <div class="d-middle border-bottom mx-0" style="height:40px;">
                                    <p class="col-auto f-17 f-600 text-general">
                                        Rutas 54
                                    </p>
                                    <el-switch
                                    v-model="ruta_activa"
                                    class="ml-auto mr-2"
                                    active-color="#27CB78"
                                    inactive-color="#DBDBDB"
                                    />
                                    <router-link :to="{name: 'admin.rutas.rutas-entregas', params: {id_ruta: 1} }">
                                        <i class="icon-map-search-outline text-general f-22 mr-2" />
                                    </router-link>
                                    <el-tooltip placement="bottom" content="Añadir pedidos" effect="light">
                                        <div class="br-4 bg-general3 d-middle-center text-white  mr-2" style="width:20px;height:20px;">
                                            <i class="icon-plus f-14" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="d-middle border-bottom" style="height:50px;">
                                    <div class="col text-general">
                                        <p>Peso: 1000 kg</p>
                                        <p>Volumen: 10 m3</p>
                                    </div>
                                    <div class="bg-general3 rounded-circle text-white mr-2 d-middle-center" style="width:20px;height:20px;">
                                        5
                                    </div>
                                </div>
                                <div class="custom-scroll overflow-auto pt-2" style="height:calc(100vh - 314px)">
                                    <div class="row mx-0 mx-2">
                                        <div class="col-auto px-1 bg-general br-20" />
                                        <div class="col px-0 mx-2 border py-1 px-2 br-8">
                                            <div class="row mx-0">
                                                <i class="icon-pedidos text-general f-18" />
                                                <div class="col pl-2 pr-0 text-general f-15">
                                                    <p class="f-500">
                                                        No. 584521
                                                    </p>
                                                    <p>
                                                        03 Feb. 10:00 p.m
                                                    </p>
                                                </div>
                                                <div class="col-auto px-0">
                                                    <el-tooltip placement="bottom" content="Pedido parcial" effect="light">
                                                        <i class="icon-timelapse text-gris f-17" />
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                            <div class="row mx-0 align-items-center f-15 text-general2">
                                                <i class="icon-leechero-cap f-18" />
                                                <p class="col pl-2 f-500">
                                                    Luis Correa
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col bg-white border br-12 bg-white px-0">
                        <div class="d-middle border-bottom px-2" style="height:40px;">
                            <i class="icon-pedidos f-18 text-general" />
                            <div class="col pl-2 text-general f-16 f-600">
                                Pedidos 584512
                            </div>
                            <div class="btn-ver f-20 d-middle-center border" @click="quitarPedidoRuta">
                                <i class="icon-delete-outline text-general" />
                            </div>
                        </div>
                        <div class="row mx-0 border-bottom">
                            <router-link :to="{name: 'admin.rutas.ver.mapa'}" :class="`col pt-2 text-center f-15 ${$route.name == 'admin.rutas.ver.mapa' ? 'ruta-activa text-general' : 'text-general2'}`">
                                Mapa
                            </router-link>
                            <router-link :to="{name: 'admin.rutas.ver.productos'}" :class="`col pt-2 text-center f-15 ${$route.name == 'admin.rutas.ver.productos' ? 'ruta-activa text-general' : 'text-general2'}`">
                                Productos
                            </router-link>
                        </div>
                        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 296px)">
                            <router-view />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <!-- <modal-detalle-solicitud ref="DetalleSolicitud" />
        <modal-enviar-solicitud ref="enviarSolicitud" />
        <modal-editar-turno ref="editarTurno" />
        <modal-eliminar ref="eliminarTurno" titulo="Eliminar turno" mensaje="¿Desea eliminar este turno?" />
        <modal-eliminar ref="eliminarPedidoRuta" titulo="Quitar pedido de la ruta" mensaje="¿Desea quitar este pedido de la ruta?" /> -->
    </section>
</template>

<script>
export default {
    components: {
        // modalDetalleSolicitud: () => import('./partials/modalDetalleSolicitud'),
        // modalEnviarSolicitud: () => import('./partials/modalEnviarSolicitud'),
        // modalEditarTurno: () => import('./partials/modalEditarTurno')
    },
    data(){
        return{
            post: null,
            distancia: '',
            ruta_activa: ''
        }
    },
    methods: {
        detalleSolicitud(){
            this.$refs.DetalleSolicitud.toggle();
        },
        enviarSolicitud(){
            this.$refs.enviarSolicitud.toggle();
        },
        editarTurno(){
            this.$refs.editarTurno.toggle();
        },
        eliminar(){
            this.$refs.eliminarTurno.toggle();
        },
        quitarPedidoRuta(){
            this.$refs.eliminarPedidoRuta.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}
.btn-ver{
    width: 32px;
    height: 32px;
    background-image: linear-gradient(to bottom, );
}
.column-route{
    width: 310px;
}
.btn-ver{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F4F6F8);
}
.ruta-activa{
    border-bottom: 3px solid var(--text-general) !important;
}
</style>
